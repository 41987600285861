<template>
  <NotificationBase
    :notification
    :icon="{ name: 'id-card-clip-solid' }"
    :compact
  >
    <template #subject>
      {{ t('verification.verificationTitle') }}
    </template>
    <i18n-t
      v-if="status === 'rejected'"
      keypath="notifications.body.verificationRejected"
      tag="p"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else-if="status === 'userWait'"
      keypath="notifications.body.verificationActionNeed"
      tag="p"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t v-else keypath="notifications.body.verificationSuccess" tag="p">
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>

    <StButton
      v-if="showVerificationButton"
      class="button"
      size="s"
      :label="t('verification.passVerification')"
      :to="{ query: { modal: 'sumSubVerification' } }"
    />
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { VerificationNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: VerificationNotification
  compact?: boolean
}>()

const { type, status } = toRefs(notification.data)
const { t } = useI18n()

const setLevel = (lvl: number) =>
  t('dashboard.verificationLevel', { level: lvl })

const level = computed(() => {
  switch (type.value) {
    case 'actionLiveness':
      return t('notifications.body.biometric')
    case 'actionSourceOfFunds':
      return setLevel(3)
    case 'level2':
      return setLevel(2)
    default:
      return setLevel(1)
  }
})

const showVerificationButton = computed(() => status.value === 'userWait')
</script>

<style scoped>
.button {
  width: 100%;
  margin-top: var(--spacing-150);
}
</style>
